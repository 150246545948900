body {
  margin: 0;
  font-family: Roboto Slab, serif, Open Sans, sans-serif;
}

h1 {
  margin: 0px;
  font-size: 32px;
}

h2 {
  margin: 0px;
  font-size: 28px;
}

h3 {
  margin: 0px;
  font-size: 24px;
}

h4 {
  margin: 0px;
  font-size: 20px;
}

h5 {
  margin: 0px;
  font-size: 18px;
}

h6 {
  margin: 0px;
  font-size: 16px;
}

p {
  margin: 0px;
  font-size: 12px;
}

label {
  margin: 0px;
  font-size: 12px;
}

a, a:hover, a:visited, a:focus {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

ul li {
  font-size: 12px;
  padding-left: 1.3em;
}

ul li:before {
  content: "\25B8";
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

.appContainerVerticalCenter {
  display: flex;
  height: 100vh; 
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  background-color: #7d7c79;
}

.appContainerHorizontalCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.downloadButtonContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.resume {
  width: 8.5in;
  height: 11in;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.resume-content {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background: #2d2f33;
  color: white;
  padding-top: 1.625rem;;
  padding-bottom: 1.625rem;;
  padding-left: 2rem;
  padding-right: 2rem;
}

.contact-info-link {
  display: flex;
  align-items: center;
}

.contact-info-icon {
  padding-right: 0.5rem;
}

.side-bar {
  background-color: #f2f2f2;
  flex: 0 0 auto;
}

.side-bar-content {
  display: flex;
  flex-direction: column;
  width: 1.75in;
  margin-left: 2rem;
  margin-right: 2rem;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow: hidden;
  background: white;
}

.chip {
  display: inline-block;
  padding: 0 9px;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.08);
}

@media only screen and (max-height: 11in) {
  .appContainerVerticalCenter {
    display: block;
  }
}

@media only screen and (max-width: 8.5in) {
  .appContainerHorizontalCenter {
    display: block;
  }
}

@media only print {
  .appContainerVerticalCenter {
    display: block;
    overflow: hidden;
  }

  .appContainerHorizontalCenter {
    display: block;
    overflow: hidden;
  }

  .downloadButtonContainer {
    display: none;
  }
}
